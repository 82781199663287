<template>
  <div
    class="safe-area rounded-lg"
    id="faqs"
  >
    <h1
      class="font-bold mb-6 lg:text-[55px] md:text-[42px] text-[32px] text-black"
    >
      FAQs
    </h1>
    <div class="space-y-4">
      <div
        v-for="(faq, index) in faqData.slice(0, 8)"
        :key="index"
        data-reveal
        v-scroll-reveal
        class="overflow-hidden border-b-2 last:border-b-0 py-3 !m-0"
      >
        <button
          @click="toggle(index)"
          class="w-full py-2 text-left flex justify-between border-none outline-none transition-colors items-center"
        >
          <span class="h4 !font-semibold text-black">{{ faq.Q }}</span>
          <span
            class="text-gray-500 h2 !font-normal"
            :class="
              activeIndex === index
                ? 'rotate-[45deg] duration-500'
                : 'rotate-0 duration-500'
            "
            >+</span
          >
        </button>
        <div
          class="body-2 text-pretty max-w-[56rem] h-0 p-0 overflow-hidden opacity-0 transition-all duration-1000 ease-out !font-normal text-muted"
          :class="activeIndex === index ? 'open' : 'close'"
        >
          {{ faq.A }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, Ref } from "vue";

const activeIndex: Ref<number> = ref(-1);

interface Faq {
  Q: string;
  A: string;
}

interface SchemaQuestion {
  "@type": "Question";
  name: string;
  acceptedAnswer: {
    "@type": "Answer";
    text: string;
  };
}

interface SchemaFAQ {
  "@context": "https://schema.org";
  "@type": "FAQPage";
  mainEntity: SchemaQuestion[];
}

const faqData: readonly Faq[] = [
  {
    Q: "What services does your Detech Solution offer?",
    A: "We provide a wide range of IT services, including software development, IT consulting, cybersecurity solutions, cloud computing, managed IT services, and technical support.",
  },
  {
    Q: "What types of software development projects do you handle?",
    A: "We specialize in custom software development, mobile app development, web development, and enterprise solutions. Our team is experienced in various programming languages and platforms, allowing us to tailor solutions to your specific needs.",
  },
  {
    Q: "How do you manage project timelines?",
    A: "We use agile project management methodologies to ensure timely delivery and flexibility. We provide regular updates and maintain open communication with our clients throughout the project lifecycle.",
  },
  {
    Q: "What types of websites do you develop?",
    A: "We specialize in creating user-friendly websites, including corporate sites, e-commerce platforms, personal blogs, and more. Our goal is to deliver designs that exceed client expectations.",
  },
  {
    Q: "How can I request a web development service?",
    A: "To request our web development services, please contact us through our contact form or send a request directly from the web development section on our website.",
  },
  {
    Q: "Can you help migrate my existing store to a new platform?",
    A: "Yes, we provide migration services to help you transition your existing e-commerce store to a new platform, ensuring data integrity and minimal downtime.",
  },
  {
    Q: "What is SEO and why is it important?",
    A: "SEO involves optimizing your website to improve its visibility on search engines like Google. It is crucial for increasing organic traffic and enhancing your online presence.",
  },
  {
    Q: "How long does it take to see results from SEO efforts?",
    A: "SEO is a long-term strategy. While some improvements can be seen within a few months, achieving significant results typically takes 6 to 12 months.",
  },
  {
    Q: "What technologies do you use for app development?",
    A: "We specialize in developing mobile apps using technologies like React Native and Flutter, enabling cross-platform compatibility and efficient performance.",
  },
  {
    Q: "Can you develop both iOS and Android apps?",
    A: "Yes, our team can develop apps for both iOS and Android platforms, ensuring a seamless experience across devices.",
  },
  {
    Q: "What types of software solutions do you offer?",
    A: "We provide custom software development solutions, including enterprise applications, SaaS products, and specialized business software tailored to meet your specific needs.",
  },
  {
    Q: "How do you ensure the quality of the software you develop?",
    A: "We follow rigorous testing and quality assurance processes, including unit testing, integration testing, and user acceptance testing, to deliver reliable and high-quality software.",
  },
  {
    Q: "Why is UI/UX design important for my website?",
    A: "Good UI/UX design enhances the usability and aesthetic appeal of your website, making it more engaging and user-friendly, which can lead to higher user satisfaction and conversion rates.",
  },
  {
    Q: "What does the UI/UX design process involve?",
    A: "Our design process includes research, wireframing, prototyping, and usability testing to ensure that the final product meets your goals and provides an optimal user experience.",
  },
  {
    Q: "What does your IT consulting service include?",
    A: "Our IT consulting services cover areas such as software architecture, digital strategy, technology planning, and operations improvement to help you optimize your IT infrastructure.",
  },
  {
    Q: "How can IT consulting benefit my business?",
    A: "IT consulting can help you make informed decisions about technology investments, improve operational efficiency, and ensure that your IT systems align with your business objectives.",
  },
  {
    Q: "How do you handle new client inquiries and projects?",
    A: " When a new client reaches out to us, we start by conducting an initial consultation to understand your project’s requirements, goals, and vision. This consultation can be done through a meeting, call, or email—whatever is most convenient for you. During this phase, we gather all the necessary information about your business needs, target audience, budget, and any specific challenges you are facing. This discovery phase allows us to assess the scope of the project and identify the best approach to achieve your desired results. We also discuss timelines, deliverables, and any specific technologies or platforms that may be involved. Our goal is to fully understand your expectations so we can tailor our solutions to your exact needs.",
  },
  {
    Q: "How do you provide a quote for a project?",
    A: " After our initial consultation and a thorough analysis of your requirements, we prepare a detailed project proposal and quote. The quote outlines the scope of work, project timeline, and estimated costs, broken down by phases or key deliverables. We provide transparent pricing with a clear explanation of how costs are allocated, ensuring there are no hidden fees or surprises. If your project involves multiple options or phases, we can present different pricing scenarios to help you choose the best fit for your budget and goals. We take into account factors such as project complexity, required technologies, design requirements, and any ongoing maintenance or support needs when creating the quote. Our team is also open to discussing the proposal in detail to address any questions or adjustments you may need.",
  },
  {
    Q: "What happens after I approve the quote?",
    A: "Once you approve the quote and project proposal, we move forward with formalizing our partnership through a contract agreement, which outlines the agreed-upon terms, deliverables, timelines, and payment schedules. After the contract is signed, we initiate the project with a kick-off meeting to introduce you to the team working on your project and finalize the project plan. We follow a structured project management approach to keep everything on track, providing you with regular updates, progress reports, and opportunities for feedback at each stage of development. We emphasize collaboration and communication to ensure that your project not only meets but exceeds your expectations. Throughout the entire process, our goal is to make working with us a smooth, transparent, and enjoyable experience.",
  },
  {
    Q: "What types of websites do you develop?",
    A: "We specialize in developing a wide range of websites, including corporate websites, personal blogs, e-commerce sites, and custom web applications tailored to your business needs. Whether you need a simple informational site or a complex web application with advanced functionality, our team can handle it all. We focus on creating responsive and mobile-friendly designs that offer a seamless user experience across all devices. Our websites are built with scalability in mind, ensuring they can grow alongside your business. We also emphasize accessibility, making sure your site is usable for all visitors, including those with disabilities.",
  },
  {
    Q: "What technologies do you use for web development?",
    A: "Our team employs a versatile tech stack to ensure we use the best tools for each project. We commonly work with HTML, CSS, and JavaScript for front-end development, utilizing modern frameworks like React, Angular, and Vue.js to build dynamic, interactive user interfaces. On the back end, we utilize technologies such as Node.js, PHP, and Python to create powerful, scalable server-side applications. For content management systems, we often use WordPress, Joomla, or custom-built solutions depending on your needs. We also integrate various APIs and third-party services to extend the functionality of your website, such as payment gateways, social media integrations, and CRM systems.",
  },
  {
    Q: "How long does it take to complete a website?",
    A: "The timeline for web development depends on the complexity and scope of your project. A basic website with standard features typically takes about 4-8 weeks from initial consultation to launch. However, more complex projects, such as custom web applications or websites with advanced features like e-commerce, membership portals, or integrations with external systems, can take several months. We begin each project with a detailed planning phase, where we outline the project’s requirements and establish a timeline. Throughout the development process, we maintain clear communication with you, providing regular updates and opportunities for feedback to ensure the final product meets your expectations.",
  },
  {
    Q: "Can you update or redesign my existing website?",
    A: "Absolutely! We offer comprehensive website redesign services that can revamp the look and functionality of your existing site. Whether your website needs a visual refresh, improved navigation, or enhanced performance, our team can help. We start with a thorough audit of your current site to identify areas for improvement, followed by a redesign plan that addresses these issues while aligning with your brand identity. Our redesign services include updating the visual design, enhancing the user experience, optimizing for SEO, and ensuring compatibility with the latest web standards and devices. We also provide ongoing maintenance to keep your site secure and up-to-date.",
  },
  {
    Q: "What SEO services do you offer?",
    A: "Our SEO services are designed to improve your website’s visibility on search engines like Google, Bing, and Yahoo. We offer a comprehensive suite of services, including on-page optimization (improving your website's content, meta tags, and internal linking structure), off-page optimization (building high-quality backlinks and promoting your site across the web), technical SEO (enhancing site speed, mobile-friendliness, and fixing crawl errors), and local SEO to help you rank in local search results. We also provide content creation services, focusing on developing engaging and SEO-friendly content that appeals to both search engines and your target audience. Our SEO strategies are tailored to your specific goals, whether it's increasing organic traffic, improving conversion rates, or boosting brand awareness.",
  },
  {
    Q: "How do you measure SEO success?",
    A: " We measure the success of our SEO efforts through a combination of metrics and performance indicators. Key metrics include organic traffic growth, keyword rankings, click-through rates (CTR), bounce rates, and conversion rates. We also track your website’s domain authority, backlink profile, and overall visibility across search engines. Our team uses advanced analytics tools like Google Analytics, Google Search Console, and other SEO platforms to monitor performance and generate detailed reports. We provide regular updates and insights, highlighting what’s working, what needs improvement, and our strategies moving forward. Our goal is to continuously refine and optimize our approach to deliver sustainable, long-term results for your business.",
  },
  {
    Q: "How do you ensure the security of the apps you develop?",
    A: "Security is a top priority in our app development process. We implement best practices such as data encryption, secure authentication, and compliance with industry standards to protect your app and user data. Our team conducts regular security audits and penetration testing to identify potential vulnerabilities and address them promptly. We also ensure that all sensitive data is handled securely, whether stored locally on the device or transmitted over the internet. Additionally, we stay updated on the latest security trends and requirements, such as GDPR and CCPA compliance, to ensure that your app meets the necessary regulatory standards.",
  },
  {
    Q: "Can you help with app maintenance and updates?",
    A: "Yes, we offer comprehensive maintenance and update services post-launch to ensure your app remains up-to-date, secure, and fully functional. Our maintenance services include regular performance monitoring, bug fixes, and software updates to address compatibility issues with new OS versions. We also offer feature enhancements and user feedback-driven updates to keep your app relevant and competitive. Our team is dedicated to providing ongoing support, helping you adapt your app as your business grows and technology evolves.",
  },
  {
    Q: "What types of software solutions do you offer?",
    A: "We provide a broad range of custom software solutions tailored to meet your business needs. Our services include developing enterprise applications, customer relationship management (CRM) systems, inventory management solutions, data analytics tools, automation software, and more. We work closely with you to understand your specific requirements and challenges, creating a software solution that addresses your unique business goals. Our custom software is designed to integrate seamlessly with your existing systems, providing a cohesive and efficient workflow that enhances productivity and reduces manual errors.",
  },
  {
    Q: "How do you handle project management and timelines?",
    A: "We follow a structured project management approach using agile methodologies to ensure efficient development and timely delivery. Our process begins with a detailed requirements gathering phase, followed by project planning where we establish milestones and set realistic timelines. We use tools like Jira, Trello, or Asana to manage tasks and keep the project on track. Throughout the development process, we maintain transparent communication with you, providing regular progress updates, demos, and feedback sessions. This collaborative approach allows us to quickly address any issues or changes, ensuring that the project remains aligned with your expectations and business goals.",
  },
  {
    Q: "How do you ensure the quality of your software?",
    A: "Quality assurance is an integral part of our software development process. We conduct extensive testing at every stage of development, including unit testing, integration testing, and system testing to ensure the software functions as intended. Our QA team uses automated testing tools to perform regression tests, ensuring that new code changes do not affect existing functionality. We also conduct performance testing to ensure the software can handle high loads and stress without compromising on speed or reliability. Additionally, we involve clients in the user acceptance testing (UAT) phase, allowing you to validate the software against your requirements before the final deployment.",
  },
  {
    Q: "What level of support do you provide post-launch?",
    A: "Post-launch support is a critical component of our software development services. We provide ongoing maintenance and support to ensure your software remains secure, up-to-date, and fully functional. Our support services include software updates, bug fixes, performance optimization, and technical assistance. We offer flexible support packages tailored to your needs, whether you require occasional updates or a dedicated team for continuous monitoring and improvements. Our goal is to ensure that your software continues to deliver value and adapts to the evolving needs of your business.",
  },
  {
    Q: "What is the importance of UI/UX design?",
    A: "UI/UX design is crucial in creating a positive user experience and establishing a strong connection between your brand and its audience. Good design goes beyond aesthetics; it focuses on how users interact with your product and how easy it is to achieve their goals. A well-designed interface improves user satisfaction, reduces the learning curve, and increases user engagement, leading to higher conversion rates and customer retention. By prioritizing UI/UX design, you ensure that your product is not only visually appealing but also intuitive and enjoyable to use, giving you a competitive edge in the market.",
  },
  {
    Q: "How do you approach UI/UX design?",
    A: "Our approach to UI/UX design is user-centric, meaning we put your users’ needs and behaviors at the forefront of the design process. We start with in-depth user research to understand your target audience, their preferences, and pain points. This research informs our design decisions, allowing us to create wireframes and prototypes that align with your brand and user expectations. We conduct usability testing to gather feedback and make iterative improvements, ensuring the final design is both functional and delightful to use. Our design process focuses on creating visually compelling interfaces that enhance the overall user experience while meeting your business objectives.",
  },
  {
    Q: "Can you redesign existing interfaces?",
    A: "Yes, we offer redesign services to improve the look, feel, and functionality of existing interfaces. Whether your product needs a visual refresh, improved usability, or a complete overhaul, our team can help. We begin with a thorough analysis of your current design to identify areas that need enhancement, followed by a redesign strategy that addresses these issues while preserving the essence of your brand. Our redesign services aim to modernize your interface, making it more engaging and easier to use, ultimately leading to better user satisfaction and increased business performance.",
  },
  {
    Q: "What IT consulting services do you provide?",
    A: "Our IT consulting services are designed to help businesses navigate the complex landscape of technology and make informed decisions that drive growth and efficiency. We provide a wide range of services, including technology strategy development, software architecture planning, IT infrastructure optimization, and digital transformation consulting. Our experts work closely with your team to assess your current IT environment, identify gaps, and develop tailored solutions that align with your business objectives. Whether you need help with system integration, process automation, or cloud migration, we provide strategic guidance to ensure your technology investments deliver maximum value.",
  },
  {
    Q: "How can IT consulting benefit my business?",
    A: "IT consulting can significantly benefit your business by aligning technology with your strategic goals. Our consultants help you optimize your IT infrastructure, reduce operational costs, and improve overall efficiency. By leveraging the latest technologies and best practices, we can help you streamline your operations, enhance cybersecurity, and create a more agile and responsive IT environment. Additionally, our consulting services provide valuable insights into industry trends, helping you stay ahead of the competition and make data-driven decisions that support long-term growth and success.",
  },
  {
    Q: "Do you provide cybersecurity consulting?",
    A: "Yes, we offer comprehensive cybersecurity consulting services to help protect your business from cyber threats. Our experts conduct thorough security assessments to identify vulnerabilities in your systems, followed by tailored recommendations to enhance your security posture. We provide guidance on implementing robust security measures, such as firewalls, intrusion detection systems, encryption protocols, and secure authentication methods. We also help you develop cybersecurity policies, conduct employee training, and ensure compliance with industry standards and regulations. Our goal is to safeguard your data, minimize risks, and provide peace of mind so you can focus on running your business.",
  },
];

const faqSchema = computed((): string => {
  const schema: SchemaFAQ = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: faqData.map(
      (faq): SchemaQuestion => ({
        "@type": "Question",
        name: faq.Q,
        acceptedAnswer: {
          "@type": "Answer",
          text: faq.A,
        },
      })
    ),
  };
  return JSON.stringify(schema, null, 2);
});

const toggle = (index: number) => {
  if (activeIndex.value === index) {
    activeIndex.value = -1;
  } else {
    activeIndex.value = index;
  }
};
</script>

<style scoped>
.transition-all {
  transition: max-height 0.5s ease, padding 0.5s ease, width 0.5s ease,
    opacity 0.7s ease;
}
.closed {
  max-height: 0;
  padding: 0;
  opacity: 0;
  width: 0;
}

.open {
  max-height: auto;
  height: 100%;
  width: 100%;
  padding: 1rem 0;
  opacity: 1;
}
</style>
